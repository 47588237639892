import {
  getFirstAttributeValue,
  type BasketItem,
  type BasketResponseData,
  type CentAmount,
  type Price as BapiPrice,
  type Product,
} from '@scayle/storefront-nuxt'
import { alphabetical, group, sort } from 'radash'
import {
  RX_CONFIGURATION_ITEM_FRAME_ID,
  rxLensProductMasterKeys,
} from '~/constants/rx'
import { Action } from '~/plugins/toast'
import { getItemGroupMainItem, getItemGroupAddonItems } from '~/utils/rx'
import type { FimBasketItem } from '~/composables/useFimBasket'
import type { ProductConfigurationItem } from '~/components/ui/productConfiguration/ProductConfigurationItem'

export type BundledBasketItems<T = unknown> = Partial<Record<string, T[]>>

export const checkQuantityIncreased = (
  item: BasketItem,
  compareItem: BasketItem | undefined,
) => {
  if (compareItem === undefined) {
    return false
  }
  return item.quantity > compareItem.quantity
}

export const itemAddedOrQuantityIncreased = (
  newBasket: BasketResponseData,
  oldBasket?: BasketResponseData | null,
): boolean => {
  const newItemsLength = newBasket.items.length

  const newItemAdded =
    (oldBasket && oldBasket.items.length < newItemsLength) ||
    (!oldBasket && newItemsLength > 0)

  const quantityIncreasedMap = newBasket.items.map((item) =>
    checkQuantityIncreased(
      item,
      oldBasket?.items.find((i: BasketItem) => i.key === item.key),
    ),
  )

  return newItemAdded || quantityIncreasedMap.some((item) => item)
}

const getConfigurationSum = (
  productConfiguration: ProductConfigurationItem[],
) =>
  productConfiguration.reduce(
    (sum, item) =>
      sum +
      (item?.price?.withTax ??
        (typeof item.value === 'number' ? item.value : 0)),
    0,
  )

export const getRxItemPriceValue = (
  productValue: number | string,
  productConfiguration: ProductConfigurationItem[],
) => {
  const configurationSum = getConfigurationSum(productConfiguration)

  const hasFrameConfiguration = productConfiguration.some(
    (item) => item.id === RX_CONFIGURATION_ITEM_FRAME_ID,
  )

  return typeof productValue === 'number' && !hasFrameConfiguration
    ? productValue + configurationSum
    : configurationSum
}

export const getRxItemPrice = (
  productPrice: BapiPrice,
  productConfiguration: ProductConfigurationItem[],
): BapiPrice => {
  const configurationSum = getConfigurationSum(productConfiguration)
  const hasFrameConfiguration = productConfiguration?.some(
    (item) => item.id === RX_CONFIGURATION_ITEM_FRAME_ID,
  )

  const withTax = hasFrameConfiguration
    ? (configurationSum as CentAmount)
    : ((productPrice.withTax + configurationSum) as CentAmount)

  return {
    ...productPrice,
    withTax,
  }
}

export const getItemUnitPrice = (item: FimBasketItem): BapiPrice => {
  const configurationItems = item.customData?.configurationItems
  // RX product
  if (configurationItems) {
    return getRxItemPrice(item.variant.price, configurationItems)
  }
  // Regular product
  return item.price.unit
}

export const isQuantityChangeDisabled = (product: Product) =>
  getFirstAttributeValue(product?.attributes ?? {}, 'isQuantityChangeDisabled')
    ?.value === 'true'

export const isFrameOrder = (item: FimBasketItem | FimOrderItem) =>
  item.customData?.frameOrder ?? false

/**
 * Check if item group has the required main item and all configuration items
 */
export const validateRxItem = (
  itemGroupId: string,
  items: FimBasketItem[],
): boolean => {
  const mainItem = getItemGroupMainItem(items, itemGroupId)
  if (!mainItem) {
    return false
  }

  const confItemsKeys = getItemGroupAddonItems(items, itemGroupId).map(
    (item) => (item as FimBasketItem).product.masterKey,
  )
  const missingConfItemsKeys: string[] = []

  rxLensProductMasterKeys.forEach((key) => {
    if (!confItemsKeys.includes(key)) {
      missingConfItemsKeys.push(key)
    }
  })

  return !missingConfItemsKeys.length
}

export const showAddToBasketToast = (
  isAddedToBasket: boolean,
  item: Product | null,
) => {
  const { $alert, $i18n } = useNuxtApp()

  const productName =
    getFirstAttributeValue(item?.attributes, 'name')?.label ||
    $i18n.t('wishlist.product')

  const message = $i18n.t('basket.notification.add_to_basket_success', {
    productName,
  })

  const action = isAddedToBasket ? Action.route : Action.confirm

  $alert.show(message, action, isAddedToBasket ? routeList.basket : undefined)
}

export const getPartitionedBasketItems = (items: BasketItem[] = []) => {
  return items.reduce<Record<'standAlone' | 'groupedItems', BasketItem[]>>(
    (acc, item: BasketItem) => {
      if (item.itemGroup?.id) {
        acc.groupedItems.push(item)
      } else {
        acc.standAlone.push(item)
      }
      return acc
    },
    { standAlone: [], groupedItems: [] },
  )
}

export const sortBasketItemsByNameAndSize = (
  items: BasketItem[],
): BasketItem[] => {
  const sortedAlphabetically = alphabetical(
    items,
    (item: BasketItem) =>
      getFirstAttributeValue(item.product.attributes, 'name')?.label ?? '',
  )
  return sort(
    sortedAlphabetically,
    (item: BasketItem) =>
      getFirstAttributeValue(item.variant?.attributes, 'size')?.id ?? 0,
  )
}

export const bundleBasketItemsByGroup = (
  items: BasketItem[] = [],
): BundledBasketItems<BasketItem> => {
  return group(items, (item: BasketItem) => item.itemGroup?.id ?? '-1')
}
