export default defineNuxtPlugin({
  name: 'monetization-script',
  setup() {
    const route = useRoute()

    const { data: orderData } = route.query.cbd
      ? useOrderConfirmation({
          params: () => ({ cbdToken: route.query.cbd as string }),
        })
      : { data: ref() }

    useHead(() => {
      if (!orderData.value) return {}

      const { customer, address } = orderData.value

      return {
        script: [
          {
            key: 'vg-embed-css',
            src: 'https://vorteilsguru.de/css/vg_embed.css',
            rel: 'stylesheet',
          },
          {
            key: 'vg-embed-js',
            src: 'https://vorteilsguru.de/js/vg-embed.js',
          },
          {
            key: 'vg-embed-config',
            innerHTML: `
              function prefillCallback(firstnameField, lastnameField, streetField, streetNumberField, cityField, emailField, phoneField) {
                if (firstnameField) firstnameField.value = "${customer?.firstName ?? ''}";
                if (lastnameField) lastnameField.value = "${customer?.lastName ?? ''}";
                if (streetField) streetField.value = "${address?.billing?.street ?? ''}";
                if (streetNumberField) streetNumberField.value = "${address?.billing?.houseNumber ?? ''}";
                if (cityField) cityField.value = "${address?.billing?.city ?? ''}";
                if (emailField) emailField.value = "${customer?.email ?? ''}";
                if (phoneField) phoneField.value = "${customer?.phone ?? ''}";
              }

              const vg_config = {
                element: 'vgEmbed',
                snippetId: 'fielmann_dankesblick',
                nl_permission: false,
                contact: {
                  salutation: '${customer?.gender === 'm' ? 'Herr' : 'Frau'}',
                  zipCode: '${address?.shipping?.zipCode ?? ''}',
                  dateOfBirth: '${customer?.birthDate ?? ''}'
                }
              };

              window.addEventListener('DOMContentLoaded', function() {
                vgEmbed.loadWithPrefillCallback(vg_config, prefillCallback);
              });
            `,
          },
        ],
      }
    })
  },
})
