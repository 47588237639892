import type { Category } from '@scayle/storefront-core'
import type { ValuesType } from 'utility-types'
import { toValue } from 'vue'
import {
  PAGE_TYPE_DEFAULT_PLP,
  PAGE_TYPE_LENSES_CARE_PLP,
  PAGE_TYPE_LENSES_PLP,
} from '../constants/pageType'
import { getIsoLanguage } from './locale'

const CategoryHandle = {
  Glasses: 'glasses',
  Sunglasses: 'sunglasses',
  ContactLenses: 'contactLenses',
  LensesCare: 'lensesCare',
} as const

export const CategoryProductType = {
  sunglasses: 'sunglasses',
  hearingAids: 'hearingAids',
  hearingAidsMerchandise: 'hearingAidsMerchandise',
  merchandise: 'merchandise',
  rxGlasses: 'rxGlasses',
  contactLensCare: 'contactLensCare',
  readingAids: 'readingAids',
  contactLens: 'contactLens',
  blueLightBlockingGlasses: 'blueLightBlockingGlasses',
  unknown: 'unknown',
} as const

export type CategoryProductType = ValuesType<typeof CategoryProductType>

type CategoryCustomData = {
  [key: string]: unknown | undefined
  productType?: Record<CategoryProductType, boolean>
  Brand_Root?: boolean
  handle?: ValuesType<typeof CategoryHandle>
  sorting?: string
  sortingKey?: string
}

declare module '@scayle/storefront-core' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface CategoryShopCustomData extends CategoryCustomData {}
}

// Hopefully a temporary workaround until we have a robust way to determine
// category based on slug or some other attribute
const contactLensesPaths = {
  de: '/kontaktlinsen',
  pl: '/soczewki-kontaktowe',
  cs: '/kontaktni-cocky',
  fr: '/lentilles-de-contact',
  it: '/lenti-a-contatto',
}

const lensesCarePaths = {
  de: '/kontaktlinsen/kontaktlinsenpflege',
  pl: '/soczewki-kontaktowe/srodki-do-pielegnacji-soczewek',
  cs: '/kontaktni-cocky/prostredky-na-peci-o-kontaktni-cocky',
  fr: '/lentilles-de-contact/entretien-des-lentilles-de-contact',
  it: '/lenti-a-contatto/cura-delle-lenti-a-contatto',
}

export const getContactLensesPath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return contactLensesPaths[lang as keyof typeof contactLensesPaths]
}

export const getLensesCarePath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return lensesCarePaths[lang as keyof typeof lensesCarePaths]
}

export const flattenCategories = (
  input: MaybeRefOrGetter<Category[] | Category | undefined>,
): Category[] => {
  const categories = toValue(input)
  if (!categories) {
    return []
  }
  return (Array.isArray(categories) ? [...categories] : [categories])
    .flatMap((category) => [
      category,
      ...flattenCategories([...(category.children ?? [])]),
    ])
    .filter((category) => !category.isHidden)
}

export const isBrandRoot = (category: MaybeRefOrGetter<Category | undefined>) =>
  Boolean(toValue(category)?.shopLevelCustomData?.Brand_Root)

export const isBrandCategory = (
  activeNode: MaybeRefOrGetter<Category | undefined>,
  categories: MaybeRefOrGetter<Category[] | Category | undefined>,
) => {
  const flattenedCategories: Record<number, Category> = Object.fromEntries(
    flattenCategories(categories).map((category) => [category.id, category]),
  )

  const [, ...ids] = toValue(activeNode)?.rootlineIds ?? []

  return ids?.some((id) => isBrandRoot(flattenedCategories?.[id]))
}

export const getCategoryShopCustomData = (
  category: MaybeRefOrGetter<Category | undefined>,
) => toValue(category)?.shopLevelCustomData as CategoryCustomData

const getProductTypeFromHandle = (
  category: MaybeRefOrGetter<Category | undefined>,
) => {
  const customData = getCategoryShopCustomData(category)
  switch (customData?.handle) {
    case CategoryHandle.ContactLenses:
      return CategoryProductType.contactLens
    case CategoryHandle.Glasses:
      return CategoryProductType.rxGlasses
    case CategoryHandle.LensesCare:
      return CategoryProductType.contactLensCare
    case CategoryHandle.Sunglasses:
      return CategoryProductType.sunglasses
  }

  return undefined
}

const getProductTypeFromFilter = (
  category: MaybeRefOrGetter<Category | undefined>,
) => {
  const supportedFilter = toValue(category)?.supportedFilter || []

  if (supportedFilter.includes('numberOfLenses')) {
    return CategoryProductType.contactLens
  }

  if (
    supportedFilter.includes('rimType') &&
    supportedFilter.includes('baseColorLenses')
  ) {
    return CategoryProductType.sunglasses
  }

  if (supportedFilter.includes('rimType')) {
    return CategoryProductType.rxGlasses
  }
  if (supportedFilter.includes('preservativeFree')) {
    return CategoryProductType.contactLensCare
  }
}

export const getCategoryProductType = (
  category: MaybeRefOrGetter<Category | undefined>,
): CategoryProductType | undefined => {
  const customData = getCategoryShopCustomData(category)

  // check productType Data
  const productTypeData = customData?.productType
  if (productTypeData && Object.values(productTypeData).some((val) => !!val)) {
    return Object.entries(productTypeData).reduce(
      (result, [key, value]) => (value ? (key as CategoryProductType) : result),
      undefined as CategoryProductType | undefined,
    )
  }

  // fallback: check handle
  const fromHandle = getProductTypeFromHandle(category)
  if (fromHandle) {
    return fromHandle
  }

  // fallback: check filters
  const fromFilter = getProductTypeFromFilter(category)
  if (fromFilter) {
    return fromFilter
  }
}

export const getPlpPageTypeFromCategoryProductType = (
  categoryProductType: MaybeRefOrGetter<CategoryProductType | undefined>,
) => {
  switch (categoryProductType) {
    case CategoryProductType.contactLens:
      return PAGE_TYPE_LENSES_PLP
    case CategoryProductType.contactLensCare:
      return PAGE_TYPE_LENSES_CARE_PLP
    default:
      return PAGE_TYPE_DEFAULT_PLP
  }
}

export const getCategoryHeadline = (
  category: MaybeRefOrGetter<Category | undefined>,
  categories: MaybeRefOrGetter<Category[] | Category | undefined> = [],
) => {
  const currentCategory = toValue(category)

  const items = toValue(categories)

  const flattenedCategories = Array.isArray(items)
    ? flattenCategories(items)
    : items
      ? [items]
      : []

  const parentIds = computed(
    () =>
      currentCategory?.rootlineIds?.filter(
        (id) => id !== currentCategory?.id,
      ) ?? [],
  )

  // Use headline override if set https://aboutyou.atlassian.net/browse/SCFIM-2105
  const headlineOverride = currentCategory?.shopLevelCustomData
    ?.Headline_Override as string

  if (headlineOverride) {
    return headlineOverride
  }

  const [addons]: string[][] =
    (
      [
        currentCategory,
        [...parentIds.value].reverse().map((id) => {
          return flattenedCategories.find((cat) => cat?.id === id)
        }),
      ] as Category[]
    )
      .map((category) => {
        return [
          category?.shopLevelCustomData?.Headline_Prefix as string,
          category?.shopLevelCustomData?.Headline_Suffix as string,
        ]
      })
      .filter(([prefix, suffix]) => Boolean(prefix) || Boolean(suffix)) || []

  const [prefix, suffix] = addons || []

  return [prefix, currentCategory?.name, suffix].filter(Boolean).join(' ')
}
