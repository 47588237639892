import revive_payload_client_4sVQNw7RlN from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MIdcuyf0AL from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/modules/fim-gtm/runtime/plugin.ts";
import plugin_client_4F1kZh3YAB from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_KlVwwuJRCL from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import i18n_yfWm7jX06p from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import matchedVueRoutePlugin_7XeDJxe5fE from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@scayle/nuxt-opentelemetry/dist/runtime/plugins/matchedVueRoutePlugin.js";
import log_client_D4ICxhhMvi from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@scayle/storefront-nuxt/dist/runtime/plugin/log.client.js";
import shop_dMlX3kAbvY from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@scayle/storefront-nuxt/dist/runtime/plugin/shop.js";
import reportPlugin_7SFdTkRWY0 from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/modules/serverSideTracking/runtime/reportPlugin.ts";
import _01_tracking_OPtsZEwIsT from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/01.tracking.ts";
import _02_routeChangeTrackingObserver_client_pbkB4McNNd from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/02.routeChangeTrackingObserver.client.ts";
import dataDogTest_9oBUGNXdsX from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/dataDogTest.ts";
import dompurify_client_LBNoFXVzoi from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/dompurify.client.ts";
import featureToggle_6huLtR7Thw from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/featureToggle.ts";
import fimLocalePath_p4aWujMMAh from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/fimLocalePath.ts";
import fittingbox_client_P8wFifr7O2 from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/fittingbox.client.ts";
import fraud0_6JzjSSAeyO from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/fraud0.ts";
import maska_UHaKf2z1iQ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/maska.ts";
import monetization_script_nsd1wvpDk9 from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/monetization-script.ts";
import onetrust_script_i5ROHACheV from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/onetrust-script.ts";
import syte_zBX7ifTOHQ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/syte.ts";
import toast_ysMjUcU7eJ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/toast.ts";
import validation_30a3L3OexF from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_MIdcuyf0AL,
  plugin_client_4F1kZh3YAB,
  plugin_KlVwwuJRCL,
  plugin_iGLjBbor9q,
  i18n_yfWm7jX06p,
  matchedVueRoutePlugin_7XeDJxe5fE,
  log_client_D4ICxhhMvi,
  shop_dMlX3kAbvY,
  reportPlugin_7SFdTkRWY0,
  _01_tracking_OPtsZEwIsT,
  _02_routeChangeTrackingObserver_client_pbkB4McNNd,
  dataDogTest_9oBUGNXdsX,
  dompurify_client_LBNoFXVzoi,
  featureToggle_6huLtR7Thw,
  fimLocalePath_p4aWujMMAh,
  fittingbox_client_P8wFifr7O2,
  fraud0_6JzjSSAeyO,
  maska_UHaKf2z1iQ,
  monetization_script_nsd1wvpDk9,
  onetrust_script_i5ROHACheV,
  syte_zBX7ifTOHQ,
  toast_ysMjUcU7eJ,
  validation_30a3L3OexF
]