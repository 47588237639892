<template>
  <div class="flex w-full flex-col-reverse gap-5 lg:flex-row">
    <FimButton
      v-if="!containsRxGlassesWithoutPrescriptionValues"
      class="w-full"
      @click="goToCheckout"
    >
      {{ $t('basket.checkout_label') }}
    </FimButton>
    <FimButton
      :to="localePath(routeList.basket)"
      type="secondary"
      class="w-full"
    >
      {{ $t('basket.heading') }}
    </FimButton>
  </div>
</template>

<script setup lang="ts">
import { useFimBasket } from '~/composables/useFimBasket'
import { useToast } from '~/composables/useToast'
import { Action } from '~/plugins/toast'

const emit = defineEmits(['click:begin_checkout'])
const { logger } = useLogging()
const basket = await useFimBasket()
const router = useRouter()
const { alert } = useToast()
const $i18n = useI18n()
const localePath = useFimLocalePath()
const goToCheckout = async () => {
  try {
    await basket.handleInvalidBasketItems()
    emit('click:begin_checkout')
    router.push(localePath(routeList.checkout))
  } catch (error: any) {
    logger.error(error, {
      why: 'basket.handleInvalidBasketItems()',
      where: 'HeaderBasketPopoverActions.vue',
    })

    alert($i18n.t('pdp.basket.unavailable'), Action.confirm)
  }
}

const containsRxGlassesWithoutPrescriptionValues = computed(() => {
  return basket.items?.value.some(isRxGlassesWithoutPrescriptionValues)
})
</script>
