import type {
  RpcContext,
  RpcMethodName,
  RpcMethodParameters,
  RpcMethodReturnType,
} from '@scayle/storefront-nuxt'
import { rpcCall } from '@scayle/storefront-nuxt'
import { hash, objectHash } from 'ohash'

export const useAsyncRpc = <
  N extends RpcMethodName,
  P extends RpcMethodParameters<N>,
  ResT extends Exclude<Awaited<RpcMethodReturnType<N>>, Response>, // Response Type
  DataT = ResT, // Transform function response type
  TParams = P extends RpcContext ? undefined : P,
>(
  method: N,
  params?: MaybeRefOrGetter<TParams>,
  options: AsyncRpcOptions<N, ResT, DataT> = {},
  key?: string,
) => {
  const nuxtApp = useNuxtApp()
  const currentShop = useCurrentShop()
  const { logger } = useLogging()
  const { condition, defaultValue, ...asyncDataOptions } = options

  const wrappedCall = rpcCall(nuxtApp, method, currentShop.value)

  if (condition && (isRef(condition) || typeof condition === 'function')) {
    asyncDataOptions.watch = [...(asyncDataOptions.watch || []), condition]
  }

  return useAsyncData<ResT, unknown, DataT>(
    key ||
      `${currentShop.value?.shopId}:${method}:${hash(objectHash(toValue(params)))}`,
    async () => {
      try {
        if (typeof condition === 'undefined' || toValue(condition)) {
          return await wrappedCall(toValue(params) as P)
        }

        if (typeof defaultValue === 'undefined') {
          throw new Error('No default value provided')
        }

        return Promise.resolve(defaultValue)
      } catch (error) {
        logger.error(error, {
          why: `rpc:${method}`,
          where: 'useAsyncRpc.ts',
        })
        throw error
      }
    },
    {
      dedupe: 'defer',
      ...asyncDataOptions,
    },
  )
}
