import type { ProductWith } from '@scayle/storefront-nuxt'

export const PRODUCTS_PER_PAGE = 24
export const MINIMUM_QUANTITY_IMMEDIATE_AVAILABILITY = 5
export const PRODUCT_WITH_PARAMS: ProductWith = {
  attributes: {
    withKey: [
      'color',
      'brand',
      'name',
      'promotion',
      'fastenerType',
      'design',
      'extras',
      'material',
      'print',
      'careInstructions',
      'fitting',
      'upperLength',
      'sleeveLength',
      'shirtCut',
      'shortsLength',
      'trousersLength',
      'skirtLength',
      'neckline',
      'trousersCut',
    ],
  },
  advancedAttributes: {
    withKey: [
      'materialCompositionTextile',
      'productDescription',
      'combineWith',
      'additionalService',
    ],
  },
  variants: {
    attributes: {
      withKey: ['price', 'size'],
    },
    lowestPriorPrice: true,
  },
  images: 'all',
  categories: 'all',
  siblings: {
    images: {
      attributes: {
        withKey: ['imageType', 'imageView', 'imageBackground'],
      },
    },
    attributes: {
      withKey: ['color', 'name', 'brand'],
    },
  },
  priceRange: true,
  lowestPriorPrice: true,
}

export const PRODUCT_IMAGE_QUALITY_MODIFIER = '75'

export const PRODUCT_LIFECYCLE_STATUS_ENRICHMENT = 'enrichment'
export const PRODUCT_LIFECYCLE_STATUS_PUBLISHED = 'published'
export const PRODUCT_LIFECYCLE_STATUS_TEMPORARY_NO_SELLABLE =
  'temporary_not_sellable'
export const PRODUCT_LIFECYCLE_STATUS_OFFLINE = 'offline'

export const PRODUCT_LIFECYCLE_STATUSES = [
  PRODUCT_LIFECYCLE_STATUS_ENRICHMENT,
  PRODUCT_LIFECYCLE_STATUS_PUBLISHED,
  PRODUCT_LIFECYCLE_STATUS_TEMPORARY_NO_SELLABLE,
  PRODUCT_LIFECYCLE_STATUS_OFFLINE,
] as const

export type ProductLifecycleStatus = (typeof PRODUCT_LIFECYCLE_STATUSES)[number]

export const PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_UPSELL =
  'recommendationRefUpsell' as const
export const PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_SUBSTITUION =
  'recommendationRefSubstitution' as const
export const PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_RELATED =
  'recommendationRefRelated' as const
export const PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_XSELL =
  'recommendationRefXsell' as const

export const PRODUCT_RECOMMENDATION_ATTRIBUTES = [
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_UPSELL,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_SUBSTITUION,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_RELATED,
  PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_XSELL,
] as const

export type ProductRecommendationAttribute =
  (typeof PRODUCT_RECOMMENDATION_ATTRIBUTES)[number]

export const PRODUCT_RECOMMENDATION_ATTRIBUTE_TITLE_TRANSLATIONS: Record<
  ProductRecommendationAttribute,
  string
> = {
  [PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_RELATED]: 'pdp.related',
  [PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_UPSELL]: 'pdp.upsell',
  [PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_SUBSTITUION]: 'pdp.substitution',
  [PRODUCT_RECOMMENDATION_ATTRIBUTE_REF_XSELL]: 'pdp.similar_products',
}

export const PRODUCT_DETAIL_ATTRIBUTE_KEYS_EYEWEAR = [
  'brand',
  'modelName',
  'manufacturerColorCode',
  'targetGroup',
  'sports',
  // missing key attribute 'für Brillenträger geeignet' (suitable for glasses wearers)
  'faceShape',
  'shape',
  'rimType',
  'bridgeType',
  'hingeType',
  'availableWithVisualAcuity',
  'sapMaterial',
  'frameColor',
  'lensMaterial',
  'lensBaseColor',
  'tintingType',
  'lensAttributesShade', // rx
  'lensAttributesFilters',
  'lensAttributesFilter', // rx
  'filterCategory',
  // missing key attribute 'Lichtdurchlässigkeit' (opacity?)
  'weatherConditions',
]

export const PRODUCT_DETAIL_ATTRIBUTE_KEYS_CF = [
  'name',
  'brand',
  'manufacturerNamePublic',
  'productType',
  'suitableFor',
  'careType',
  'compatibleWith',
  'isPreservativeFree',
  'contentDescription',
  'umweltschonend',
  'preservativeFree',
  'deliveryContent',
]

export const PRODUCT_DETAIL_ATTRIBUTE_KEYS_CL = [
  'name',
  'brand',
  'manufacturerNamePublic',
  'wearingTime',
  'productType',
  'waterGradient',
  'oxygenPermeability',
  'diameter',
  'contactLensMaterial',
  'handlingTint',
  'uvProtection',
  'uvBlocking',
  'diameter',
  'usageTime',
]

export const PRODUCT_DETAIL_ATTRIBUTE_KEYS_HEARING_AIDS = [
  'productNameLong',
  'brand',
  'color',
  'productType',
  'packingTypeDescription',
  'deliveryContent',
  'reusable',
  'rangeOfApplication',
  'suitableFor',
  'ageGroup',
  'noiseInsulation',
  'degreeOfHearingLoss',
  'personalProtectiveEquipment',
  'medicalProduct',
]
