export default defineNuxtPlugin((_context) => {
  const { $currentShop } = useNuxtApp()
  const src = $currentShop?.syteScriptTagUrl
  if (!src || !getIsSyteEnabled($currentShop)) {
    return
  }
  useHead(() => ({
    link: [
      {
        key: 'syte-preconnect',
        rel: 'preconnect',
        crossorigin: '',
        href: 'https://cdn.syteapi.com',
      },
      {
        key: 'syte-api-preconnect',
        rel: 'preconnect',
        crossorigin: '',
        href: 'https://cdn-api.syteapi.com',
      },
    ],
    script: [
      {
        key: 'syte-script',
        src,
        type: 'text/javascript',
        async: true,
      },
    ],
  }))
})
