export default defineNuxtPlugin((_context) => {
  const { $currentShop } = useNuxtApp()

  if (!isFraud0Enabled($currentShop)) return

  const src = `https://bt.fraud0.com/api/v2/fz.js?cid=${$currentShop?.fraud0TagId}`

  useHead(() => ({
    link: [
      {
        key: 'fraud0-preconnect',
        rel: 'preconnect',
        crossorigin: '',
        href: 'https://bt.fraud0.com',
      },
    ],
    script: [
      {
        key: 'fraud0-script',
        src,
        async: true,
        tagPosition: 'bodyOpen',
      },
    ],
  }))
})
