export const formatAllowedHtml = (html: string | null | undefined) => {
  if (!html) return undefined

  // Replace h1 with h2
  html = html.replace(/<h1(.*?)>/g, '<h2$1>').replace(/<\/h1>/g, '</h2>')

  // Reduce subsequest br
  html = html.replace(/(<br\s*\/?>)+/g, '<br>')

  // Remove br before, after or at beginning or end of elements
  html = html.replace(/><br>/g, '>').replace(/<br></g, '<')

  // Remove all empty specified tags
  const tags = [
    'div',
    'span',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'table',
    'li',
  ]
  html = html.replace(
    new RegExp(`<(${tags.join('|')})\\b[^>]*>\\s*<\\/\\1>`, 'g'),
    '',
  )

  return html
}

export const parseMarkdownAttribute = (text: string | null | undefined) => {
  return text
    ?.replace(/\n/g, '<br>')
    .replace(/\\n/g, '<br>')
    .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>')
    .replace(/\*([^*]+)\*/g, '<i>$1</i>')
}

export const removeWhitespace = (text: string) => {
  return text.replace(/[\n\r\t]/gm, '').trim()
}
