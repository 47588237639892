const usePageViewEvents = (
  track: (
    event: TrackingEvent,
    payload: TrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  const router = useRouter()

  return {
    trackPageView: (
      params: Omit<Partial<TrackContentViewEventParams>, 'pageType'> &
        Pick<TrackContentViewEventParams, 'pageType'>,
      options?: MapToTrackingPayloadOptions,
    ) => {
      const route = toValue(router.currentRoute)

      const {
        eventName,
        contentName,
        title,
        pageType,
        pageTypeId,
        error,
        meta,
      } = params

      track(
        eventName || 'FielmannBasic_VirtualPageview',
        {
          content_name: contentName || route.path,
          title: title || document?.title,
          page_type: pageType,
          page_type_id: pageTypeId || route?.params?.slug?.toString() || '',
          error,
          meta,
        },
        options,
      )
    },
  }
}

export default usePageViewEvents
