import { defineNuxtPlugin, useRouter } from 'nuxt/app'
import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm'
import type { CustomStoreConfig } from '~/config/stores/types'

function getCurrentShopGtmConfig(
  shopGtmConfig: Record<string, CustomStoreConfig['gtm']> | undefined,
  currentHost: string,
): CustomStoreConfig['gtm'] | undefined {
  return Object.entries(shopGtmConfig || {}).find(([domain]) =>
    currentHost.includes(domain.split(':')[0]),
  )?.[1]
}

export default defineNuxtPlugin((nuxt) => {
  if (import.meta.client) {
    const { shopGtmConfig, ...options } = nuxt.$config.public.gtm

    const currentShopGtmConfig =
      typeof window !== 'undefined'
        ? getCurrentShopGtmConfig(shopGtmConfig, window.location.hostname)
        : {}

    const router = useRouter()

    const pluginOptions: VueGtmUseOptions = {
      ...options,
      ...currentShopGtmConfig,
      vueRouter:
        options.enableRouterSync && router
          ? (router as VueGtmUseOptions['vueRouter'])
          : undefined,
    }

    nuxt.vueApp.use(createGtm(pluginOptions))
  }
})
