import type { Product } from '@scayle/storefront-nuxt'
import { useGlassesLenses } from '~/composables/pdp/useGlassesLenses'
import type { CustomData } from '~/rpcMethods/customData'
import { getIsSyteEnabled } from '~/utils/syte'
import { isAuth0Active } from '~/utils/authentication'

type ZeroCostFramesConfig = CustomData['zeroCostFrames'] | undefined
type RopoConfig = CustomData['ropo'] | undefined

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const currentShop = useCurrentShop()
    const config = useRuntimeConfig()
    const { lensesProducts } = useGlassesLenses()
    const { data: customData } = await useCustomData()
    const route = useRoute()

    // Styte
    const isSyteEnabled = computed(() => getIsSyteEnabled(currentShop.value))

    // RX Config
    const isRxConfiguratorEnabled = (
      product: MaybeRefOrGetter<Product | undefined>,
    ) => {
      const p = toValue(product)
      if (!p) {
        return false
      }
      const enabled =
        (currentShop.value?.rxConfiguratorEnabled ?? false) &&
        (customData?.value?.correctionalGlasses?.enabled ?? false)

      const availableQualities = getRxCountryIndexAvailability(
        p,
        currentShop.value,
      )

      const attribute = getFirstAttributeLabel(
        p?.attributes ?? {},
        'availableWithVisualAcuity',
      )

      const isRxFrame = isCorrectionalGlasses(p)
      const isEligibleProduct =
        !!currentShop.value?.rx?.sunglasses &&
        isGlasses(p) &&
        ['1', 'ja', 'true'].includes(attribute?.toLowerCase())

      const lensesAvailable =
        (toValue(lensesProducts) || []).filter((item) =>
          availableQualities.includes(item?.referenceKey || ''),
        ).length > 0

      return (isRxFrame || isEligibleProduct) && !!enabled && lensesAvailable
    }
    const isRxFreeShippingEnabled = () =>
      customData?.value?.rxFreeShipping?.enabled ?? false
    const zeroCostFramesConfig = computed<ZeroCostFramesConfig>(
      () => customData.value?.zeroCostFrames,
    )
    const isZeroCostEnabled = () => {
      return Boolean(zeroCostFramesConfig.value?.enabled ?? false)
    }
    const isPdToolEnabled = () => customData?.value?.pdTool?.enabled ?? false

    // ROPO
    const ROPO_COOKIE_NAME = 'isRopoEligible'
    const isRopoEligible = useState('isRopoEligible', () => false)

    const ropoCookie = useCookie<boolean>(ROPO_COOKIE_NAME, {
      encode: (value) => (value ? 'true' : 'false'),
      decode: (value) => value === 'true',
      watch: true,
    })

    const ropoConfig = computed<RopoConfig>(() => {
      return customData.value?.ropo
    })
    const setIsRopoEligible = (value: boolean) => {
      isRopoEligible.value = value
      ropoCookie.value = value
    }

    const refreshRopoCookie = () => refreshCookie(ROPO_COOKIE_NAME)

    // Default to the ref value if cookie is undefined, because setting the cookie
    // does not cause it to update in all instances of the composable
    const getIsRopoEligible = computed(
      () => ropoCookie.value || isRopoEligible.value,
    )
    const isRopoEnabled = computed(() =>
      Boolean(
        getIsRopoEligible.value &&
          (ropoConfig.value?.enabled ?? false) &&
          (ropoConfig.value?.maxNumberOfArticles ?? 0) &&
          (ropoConfig.value?.supportedStores.length ?? 0),
      ),
    )

    const ropoMaxNumberOfArticles = computed(() =>
      isRopoEnabled.value && customData?.value?.ropo?.maxNumberOfArticles
        ? customData?.value?.ropo?.maxNumberOfArticles
        : 0,
    )

    const ropoSupportedStores = computed(
      () => customData?.value?.ropo?.supportedStores ?? [],
    )

    const isNewAccount = computed(
      () => (config.public.fielmann.account.version ?? 1) === 2,
    )

    const isDdoaEnabled = computed(() => !!currentShop?.value?.ddoa?.enabled)

    const isSubscriptionEnabled = computed(
      () => !!currentShop?.value?.subscriptionEnabled,
    )

    const isStoreMode = computed(() =>
      Boolean(getQueryParam(route?.query, 'storeMode')),
    )

    const isMonetizationEnabled = computed(
      () => !!currentShop?.value?.monetizationEnabled,
    )

    return {
      provide: {
        featureToggle: {
          isSyteEnabled,
          isRxConfiguratorEnabled,
          isRxFreeShippingEnabled,
          zeroCostFramesConfig,
          isZeroCostEnabled,
          isPdToolEnabled,
          isRopoEnabled,
          ropoMaxNumberOfArticles,
          setIsRopoEligible,
          refreshRopoCookie,
          ropoSupportedStores,
          ropoConfig,
          isNewAccount,
          isDdoaEnabled,
          isSubscriptionEnabled,
          isStoreMode,
          isMonetizationEnabled,
        },
      },
    }
  },
})

export type FeatureTogglePlugin = {
  isSyteEnabled: ComputedRef<boolean>
  isRxConfiguratorEnabled: (
    product: MaybeRefOrGetter<Product | undefined>,
  ) => boolean
  isRxFreeShippingEnabled: () => boolean
  zeroCostFramesConfig: ComputedRef<ZeroCostFramesConfig>
  isZeroCostEnabled: () => boolean
  isPdToolEnabled: () => boolean
  isRopoEnabled: ComputedRef<boolean>
  ropoMaxNumberOfArticles: ComputedRef<number>
  setIsRopoEligible: (value: boolean) => void
  refreshRopoCookie: () => void
  ropoSupportedStores: ComputedRef<string[]>
  ropoConfig: ComputedRef<RopoConfig>
  isNewAccount: ComputedRef<boolean>
  isDdoaEnabled: ComputedRef<boolean>
  isSubscriptionEnabled: ComputedRef<boolean>
  isStoreMode: ComputedRef<boolean>
  isMonetizationEnabled: ComputedRef<boolean>
}
