import type { Variant } from '@scayle/storefront-nuxt'

const useVTOEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackVTOFittingBoxOpen: ({
      productDetails,
      variant,
      element,
    }: FimVtoTrackingPayload & { variant: Variant }) => {
      track(
        'FielmannBasic_VirtualTryOn',
        { productDetails, element },
        { variant },
      )
    },
    trackVTOFittingBoxClose: ({
      productDetails,
      variant,
      element,
    }: FimVtoTrackingPayload & { variant: Variant }) => {
      track(
        'FielmannBasic_CloseVirtualTryOn',
        { productDetails, element },
        { variant },
      )
    },
  }
}

export default useVTOEvents
