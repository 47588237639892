import {
  getTotalAppliedReductions,
  type CentAmount,
  type Price,
  type Order,
  type LowestPriorPrice,
} from '@scayle/storefront-nuxt'
import { toLocaleCurrency } from './locale'

type AppliedFees = Order['cost']['appliedFees']

export const getShippingNetFee = (appliedFees: AppliedFees): number | null => {
  if (!appliedFees?.length) {
    return null
  }

  const shippingNetFees = appliedFees?.filter((fee) => {
    return fee.category === 'delivery'
  })

  return shippingNetFees.reduce((total, fee) => {
    total += fee.amount.withoutTax
    return total
  }, 0)
}

export const divideWithHundred = (price: number): number => price / 100

export const toCurrency = (
  value: number,
  localeOptions?: ObjectWithLocaleAndCurrency,
): string => {
  const $currentShop = useCurrentShop()
  return toLocaleCurrency(
    value as CentAmount,
    localeOptions || $currentShop.value,
  )
}

export const calculateFallbackPriorPrice = (price: Price) => {
  const totalAppliedReductions = getTotalAppliedReductions(price)

  return {
    withTax: price.withTax + totalAppliedReductions.absoluteWithTax,
    relativeDifferenceToPrice: totalAppliedReductions.relative * -1,
  }
}

export const getLowestPriorPrice = (
  price?: Price,
  lowestPriorPrice?: LowestPriorPrice,
  quantity: number = 1,
) => {
  if (!price?.appliedReductions?.length) {
    return null
  }

  if (lowestPriorPrice?.withTax) {
    return toCurrency(lowestPriorPrice?.withTax * quantity)
  }

  const fallbackPriorPrice = calculateFallbackPriorPrice(price)
  return toCurrency(fallbackPriorPrice.withTax * quantity)
}

export const getBasePriceFormatted = (
  unitSize: string,
  referencePrice: number,
  referenceUnit: string,
) => `${unitSize} (${toCurrency(referencePrice)}/${referenceUnit})`

export const getOriginalPrice = (price: Price) => {
  const appliedReductions = price.appliedReductions.reduce(
    (result, item) => item.amount.absoluteWithTax + result,
    0,
  )

  return appliedReductions + price.withTax
}

export const sumUpPrice = (prices: Array<Price>) => {
  // NOTE we assume all prices do have the same vat

  const combinedPrice = prices.reduce<Price>(
    (acc, curr) => {
      acc.withTax = (curr.withTax + acc.withTax) as CentAmount
      acc.withoutTax = (curr.withoutTax + acc.withoutTax) as CentAmount
      acc.tax.vat.amount = (curr.tax.vat.amount +
        acc.tax.vat.amount) as CentAmount
      acc.appliedReductions.push(...(curr?.appliedReductions || []))

      return acc
    },
    {
      withoutTax: 0 as CentAmount,
      withTax: 0 as CentAmount,
      tax: {
        vat: {
          amount: 0 as CentAmount,
          rate: prices[0].tax.vat.rate,
        },
      },
      appliedReductions: [],
      currencyCode: prices[0].currencyCode,
    },
  )
  combinedPrice.appliedReductions = combinedPrice.appliedReductions.map(
    (appliedReduction) => {
      const amount = appliedReduction?.amount ?? {}
      const absoluteReductionWithTax = amount?.absoluteWithTax ?? 0
      const relative =
        Math.floor(
          (absoluteReductionWithTax /
            (combinedPrice.withTax + absoluteReductionWithTax)) *
            100,
        ) / 100
      return { ...appliedReduction, amount: { ...amount, relative } }
    },
  )

  return combinedPrice
}

export const getGrossPrice = (price: Price) =>
  (price.withTax / 100).toFixed(2).toString()

export const divideByHundred = (price: number): number => price / 100
