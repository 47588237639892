export default defineNuxtRouteMiddleware(async (to, from) => {
  const currentShop = useCurrentShop()

  if (to.path.includes('/api') || !currentShop.value) {
    return
  }

  const nuxt = useNuxtApp()
  const getLocalePath = useFimLocalePath()

  const { updateNuxtUserData, data, refresh } = useFimUser()
  const fetchtUserInfo = useRpcCall('getUserInfo')

  if (!nuxt.ssrContext && !data?.value) {
    await refresh()
  }

  const user = nuxt?.ssrContext
    ? nuxt?.ssrContext?.event?.context.$rpcContext.user
    : data?.value

  const localePath = (routePath: LinkList[keyof LinkList]['path']) => {
    return getLocalePath(routePath) || routePath
  }

  const isProtectedRoute = (exclude?: string) => {
    const routes = getProtectedRouteList(exclude)

    return routes.find(
      (protectedRoute) => localePath(protectedRoute) === to.path,
    )
  }

  const isGuest = !!user?.status?.isGuestCustomer

  const isProtectRouteForGuest = isGuest && isProtectedRoute('checkout')

  if (!user && isProtectedRoute()) {
    return navigateTo({
      path: localePath(routeList.signin.path),
      query: {
        redirectUrl: to.fullPath,
        showBasket: `${to.fullPath === routeList.checkout.path}`,
      },
    })
  }

  // All protected account pages which are not account + account/user are not allowed for un-verified user
  const needsVerifiedUser =
    isProtectedRoute() &&
    to.path.startsWith(routeList.account.path) &&
    ![
      localePath(routeList.account.path),
      localePath(routeList.user.path),
    ].includes(to.path)

  // we need userinfo on the account
  if (user && !user.info && needsVerifiedUser) {
    user.info = await fetchtUserInfo()
  }

  if (user) {
    updateNuxtUserData(user)
  }

  if (user && !user.info?.verified && needsVerifiedUser) {
    return navigateTo({ path: routeList.account.path })
  }

  if (user && isProtectRouteForGuest) {
    const redirectPath =
      getQueryParam(to.query, 'redirectUrl') || localePath(routeList.home.path)
    return navigateTo({ path: redirectPath })
  }

  if (user && !isGuest && localePath(routeList.signin.path) === to.fullPath) {
    return navigateTo({ path: routeList.account.path })
  }
})
