import type { ShopUser } from '@scayle/storefront-core'

const useUserEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackLogin: (user: MaybeRefOrGetter<ShopUser | undefined>) => {
      track('FielmannBasic_LogIn', { user })
    },
    trackSignup: (user: MaybeRefOrGetter<ShopUser | undefined>) => {
      track('FielmannBasic_SignUp', { user })
    },
  }
}

export default useUserEvents
