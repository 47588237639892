import type { ShopUser } from '@scayle/storefront-core'
import type { FimShopUser } from '~/types/user'

export const NUXT_DATA_USER_KEY = 'fim-user'
export const useFimUser = () => {
  const { data } = useNuxtData<FimShopUser | undefined>(NUXT_DATA_USER_KEY)

  const getUser = useRpcCall('getUser')

  const refresh = async () => {
    const { user } = await getUser()
    if (!user) {
      data.value = undefined
      return
    }

    data.value = user as FimShopUser
    return user
  }

  const refreshNuxtUserData = () => refreshNuxtData(NUXT_DATA_USER_KEY)
  const clearNuxtUserData = () => clearNuxtData(NUXT_DATA_USER_KEY)
  const updateNuxtUserData = (
    user: MaybeRefOrGetter<FimShopUser | ShopUser | undefined>,
  ) => {
    data.value = toValue(user) as FimShopUser | undefined
  }

  return {
    data,
    refresh,
    refreshNuxtUserData,
    clearNuxtUserData,
    updateNuxtUserData,
  }
}
