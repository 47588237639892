<template>
  <Teleport :to="portalTarget">
    <div
      v-bind="$attrs"
      class="w- fixed inset-0 z-50 h-dvh w-screen overflow-y-auto"
      :class="{
        invisible: !showBackdrop,
        visible: showBackdrop,
        'flex items-center p-5 md:p-0': mobileWindowed,
      }"
      :aria-label="title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex" :class="layoutClass">
        <FadeInTransition :appear="true" :duration="300">
          <div
            v-if="open"
            class="fixed inset-0 bg-black/30"
            aria-hidden="true"
            @click="closeOverlay"
          />
        </FadeInTransition>
        <component :is="transitionComponent">
          <div
            v-if="open"
            :data-testid="dataCy"
            class="relative w-full overflow-y-auto rounded-lg bg-white text-left shadow-xl"
            :class="contentClass"
          >
            <section
              v-if="showTitle"
              class="sticky top-0 z-10 mb-8 flex w-full justify-between border-b border-solid border-neutral-20 bg-white pb-2 pt-10"
            >
              <slot name="title">
                <Headline
                  tag="h2"
                  look="h5"
                  weight="normal"
                  :class="titleClasses"
                >
                  {{ title }}
                </Headline>
              </slot>
              <FimButton
                no-padding
                class="fill-black"
                type="link-alternative"
                @click="closeOverlay"
              >
                <template #icon>
                  <LazyIconFielmannClose class="size-6" />
                </template>
              </FimButton>
            </section>
            <slot />
          </div>
        </component>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import SlideInFromRightTransition from './transitions/SlideInFromRightTransition.vue' // explicit
import SlideInFromLeftTransition from './transitions/SlideInFromLeftTransition.vue' // explicit
import SlideInFromTopTransition from './transitions/SlideInFromTopTransition.vue' // explicit
import useUiState from '~/composables/ui/useUiState'

type layoutOptions = 'center' | 'right' | 'right/3' | 'left' | 'left/3'
type enterFromOptions = 'top' | 'right' | 'left'

const props = defineProps({
  open: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  title: {
    type: String as PropType<string>,
    default: () => '',
  },
  titleClasses: {
    type: String as PropType<string>,
    default: '',
  },
  portalTarget: {
    type: String as PropType<string>, // CSS selector
    default: () => 'body',
  },
  showTitle: {
    type: Boolean as PropType<boolean>,
    default: () => true,
  },
  layout: {
    type: String as PropType<layoutOptions>,
    default: 'center',
  },
  expanded: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  autoHeight: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  enterFrom: {
    type: String as PropType<enterFromOptions>,
    default: 'top',
  },
  noPadding: {
    type: Boolean as PropType<boolean>,
    default: () => false,
  },
  dataCy: {
    type: String as PropType<string>,
    required: false,
    default: '',
  },
  mobileWindowed: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const emit = defineEmits(['update:open'])

defineOptions({ inheritAttrs: false })
const scope = effectScope()
onScopeDispose(() => scope.stop())
const { setOverlayState } = useUiState()
const showBackdrop = ref(false)
const isExpanded = toRef(props, 'expanded')

const toggle = (isOpen: boolean) => {
  setOverlayState(isOpen)
  if (isOpen) {
    showBackdrop.value = true
  } else {
    setTimeout(() => {
      showBackdrop.value = false
    }, 500)
  }
}

onMounted(() => {
  if (props.open) {
    toggle(props.open)
  }
})

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(
    () => props.open,
    (newValue) => {
      toggle(newValue)
    },
  )
})

const layoutClass = computed(() => {
  if (props.layout === 'center') {
    return [
      'm-auto',
      props.mobileWindowed ? 'h-auto' : 'h-full',
      'max-w-[600px]',
      'items-center',
    ]
  } else if (['right', 'left', 'right/3', 'left/3'].includes(props.layout)) {
    return [
      props.autoHeight ? 'items-start' : 'items-end',
      'justify-center',
      'text-center',
      'transition-all duration-300',
      props.mobileWindowed ? 'h-auto' : 'h-full',
      ['right', 'right/3'].includes(props.layout) ? 'ml-auto' : 'ml-0',
      isExpanded.value
        ? 'md:w-full'
        : ['right', 'left'].includes(props.layout)
          ? 'md:w-[50vw]'
          : 'md:w-[50vw] xl:w-[33vw]',
    ]
  }
  return []
})

const contentClass = computed(() => {
  const paddingClasses =
    props.noPadding === false ? (props.showTitle ? 'px-10 pb-10' : 'p-10') : ''
  if (['right', 'left', 'right/3', 'left/3'].includes(props.layout)) {
    return `rounded-none ${props.autoHeight ? 'h-auto' : 'h-full'} ${paddingClasses}`
  } else if (props.layout === 'center') {
    return `h-full max-h-full md:max-h-[85vh] md:h-auto ${paddingClasses}`
  }

  return undefined
})

const transitionComponent = computed(() => {
  const transitions = {
    top: SlideInFromTopTransition,
    right: SlideInFromRightTransition,
    left: SlideInFromLeftTransition,
  }
  return transitions[props.enterFrom]
})

const closeOverlay = () => {
  emit('update:open', false)
}
</script>
